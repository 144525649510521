export default defineNuxtRouteMiddleware((to, from) => {
  const id = to.params.id
  
  if (typeof id === 'string') {
    const parsedId = parseInt(id)
    if (isNaN(parsedId) || parsedId <= 0) {
      // If id is not a positive integer, redirect to a 404 page
      return abortNavigation()
    }
  } else if (Array.isArray(id)) {
    // Handle case where id might be an array (e.g., nested routes)
    return abortNavigation()
  }
})